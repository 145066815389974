const Visited = {
  list: () => {
    const list = JSON.parse(window.localStorage.getItem("visited")) || [];

    return list;
  },
  add: (id) => {
    let currentList = JSON.parse(window.localStorage.getItem("visited")) || [];
    if (!currentList.includes(id)) {
      currentList.push(id);
      currentList = currentList.slice(Math.max(currentList.length - 3, 0));

      window.localStorage.setItem("visited", JSON.stringify(currentList));
    }
  },
};

export default Visited;
