import DOMPurify from "dompurify";
import parse from "html-react-parser";

import "./index.scss";
import Img from "../Img";
import Icon from "../Icon";
import { useEffect, useState } from "react";

const AnnouncementPopup = ({ open, title, text, photoUrl, closeHandler }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  var cleanText = DOMPurify.sanitize(text);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
      setTimeout(() => {
        setIsVisible(true);
      }, 50);
    } else {
      setIsVisible(false);
      setTimeout(() => {
        setIsOpen(false);
      }, 300);
    }
  }, [open]);

  return (
    <div
      className={`AnnouncementPopup ${isOpen ? "isOpen" : ""} ${
        isVisible ? "isVisible" : ""
      }`}
    >
      <div className="popupWrapper">
        <div className="popupWindow">
          <div className="head">
            <h2>{title}</h2>
            <button onClick={closeHandler}>
              <Icon symbol="Close" />
            </button>
          </div>
          {photoUrl && <Img width={1920} height={1080} src={photoUrl} />}
          <div className="text">{parse(cleanText)}</div>
        </div>
      </div>
      <div className="popupBackdrop"></div>
    </div>
  );
};

export default AnnouncementPopup;
