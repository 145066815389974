import apiClient from "../api";
import languages from "../config/languages";

let spaceList = [];

const buildList = async () => {
  try {
    spaceList = await apiClient["/spaces"].get();

    spaceList = spaceList.map((space) => {
      const textPaths = languages.reduce((prev, language, index) => {
        const languageLabel = index === 0 ? "" : `_${language}`;

        const textPath = space.path
          ? space.path.reduce((prev, curr, index) => {
              const string =
                curr[`displayName${languageLabel}`] || curr.displayName;

              if (index < space.path.length - 1) {
                if (index === 0) {
                  return string;
                } else {
                  return `${prev} > ${string}`;
                }
              } else {
                return prev;
              }
            }, "")
          : "";

        return { ...prev, [`textPath${languageLabel}`]: textPath };
      }, {});

      return { ...space, ...textPaths };
    });
  } catch (error) {
    console.error(error.details);
  }
};

export const getSpaces = async (filters) => {
  if (spaceList.length === 0) {
    await buildList();
  }

  let filteredSpaces = spaceList;

  if (filters?.parent) {
    filteredSpaces = filteredSpaces.filter(
      (space) => space?.parent?._id === filters.parent
    );
  }

  return filteredSpaces;
};

export const getSpace = async (id) => {
  if (spaceList.length === 0) {
    await buildList();
  }

  return spaceList.find((space) => space._id === id);
};
