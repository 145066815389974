import Main from "../../components/Main";
import parse from "html-react-parser";
import Header from "../../components/Header";
import StandardHelmet from "../../components/StandardHelmet";
import { useContext } from "react";
import LanguageContext from "../../contexts/LanguageContext";

const LegalNoteView = ({ legalNote }) => {
  const { i18n } = useContext(LanguageContext);

  return (
    <Main className="LegalNote">
      <StandardHelmet title={i18n(legalNote, "title")} />

      <Header title={i18n(legalNote, "title")} backLink="/" />
      <div className="content">{parse(i18n(legalNote, "text") || "")}</div>
    </Main>
  );
};

export default LegalNoteView;
