import Icon from "../../components/Icon";
import Img from "../../components/Img";
import Main from "../../components/Main";
import getMediaUrl from "../../utils/getMediaUrl";
import parse from "html-react-parser";
import ViewArea from "../../components/ViewArea";
import { useHistory } from "react-router-dom";
import goBack from "../../utils/goBack";
import StandardHelmet from "../../components/StandardHelmet";
import Header from "../../components/Header";
import InternalLink from "../../components/InternalLink";
import { useContext } from "react";
import LanguageContext from "../../contexts/LanguageContext";

const SpaceView = ({
  space,
  shareHandler,
  isFavorite,
  addAsFavorite,
  removeAsFavorite,
}) => {
  const history = useHistory();

  const { i18n, i18nl } = useContext(LanguageContext);

  return (
    <Main className="Space">
      <StandardHelmet
        title={i18n(space, "displayName")}
        description={`${i18nl("space.head.title.1")} '${i18n(
          space,
          "displayName"
        )}' ${i18nl("space.head.title.2")} '${i18n(
          space,
          "displayName"
        )}' ${i18nl("space.head.title.3")}.`}
      />
      <Header
        notSemantic
        title={`${(
          i18n(space.hierarchyItem, "singularDisplayName") || ""
        )}`}
        backAction={() => {
          goBack(history, "/");
        }}
      />
      <div className="bannerImage">
        <Img
          src={space.photoUrl}
          alt={space.displayName}
          width={1920}
          height={1080}
        />
        <button
          onClick={() => {
            goBack(history, "/");
          }}
          title="Indietro"
        >
          <Icon symbol="CaretLeft" />
        </button>
      </div>
      <div className="intro">
        <h1 className="dashed">
          <span className="text">{i18n(space, "displayName")}</span>
          <span className="actions">
            <button title="Share" onClick={shareHandler}>
              <Icon symbol="Share" />
            </button>
            {isFavorite ? (
              <button
                className="favorite"
                title="Remove as favourite"
                onClick={removeAsFavorite}
              >
                <Icon symbol="HeartFull" />
              </button>
            ) : (
              <button title="Add as favourite" onClick={addAsFavorite}>
                <Icon symbol="HeartEmpty" />
              </button>
            )}
          </span>
        </h1>
        {space.textPath ? (
          <div className="path">{i18n(space, "textPath")}</div>
        ) : null}
        {space.labels && space.labels.length ? (
          <div className="labels">
            {space.labels.map((label) => {
              return (
                <span className="label" key={label._id}>
                  {i18n(label, "singularDisplayName")}
                </span>
              );
            })}
          </div>
        ) : null}
        <div className={`columnWrapper ${space.photoUrl ? "" : "onlyLeft"}`}>
          <div className="contentLeft">
            {space.features && space.features.length > 0 ? (
              <div className="features">
                {space.features.map((item, index) => {
                  return (
                    <div className="feature" key={index}>
                      <span className="icon">
                        {item.feature.iconUrl ? (
                          <img alt="" src={getMediaUrl(item.feature.iconUrl)} />
                        ) : null}
                      </span>
                      <span className="text">
                        {item.quantity && item.quantity > 0
                          ? item.quantity > 1
                            ? `${item.quantity} ${i18n(
                                item.feature,
                                "pluralDisplayName"
                              )}`
                            : `${item.quantity} ${i18n(
                                item.feature,
                                "singularDisplayName"
                              )}`
                          : `${i18n(item.feature, "singularDisplayName")}`}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {(space.description &&
              space.description !== "" &&
              space.description !== "<p><br></p>") ||
            (space.buttons && space.buttons.length > 0) ? (
              <div className="info">
                {space.description &&
                space.description !== "" &&
                space.description !== "<p><br><p/>" ? (
                  <div className="description">
                    {parse(i18n(space, "description") || "")}
                  </div>
                ) : null}
                {space.buttons && space.buttons.length > 0 ? (
                  <div className="buttons">
                    {space.buttons.map((button, index) => {
                      return (
                        <a key={index} href={i18n(button, "url")}>
                          {i18n(button, "text")}
                        </a>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {space.photoUrl ? (
            <div className="contentRight">
              <Img
                src={space.photoUrl}
                alt={space.displayName}
                width={1920}
                height={1080}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="content">
        <ViewArea className="path" color="gray" fullwidth>
          <h2 className="dashed" style={{ marginBottom: "15px" }}>
            {i18nl("space.directions.title")}
          </h2>
          <p className="hint">
            {i18nl("space.directions.description.1")} '
            {i18n(space, "displayName")}'
            {space.path && space.path.length > 1
              ? `: ${i18nl("space.directions.description.2")}.`
              : "."}
          </p>
          <div className="list">
            <div className="step transparent">
              <InternalLink
                className="button"
                to={`/directions?from=${space.path[0]._id}&to=${space._id}`}
              >
                {i18nl("space.directions.fromOutside")}
              </InternalLink>
              {space.path[0].entrances && space.path[0].entrances.length > 0 ? (
                <div className="entrances">
                  <p>{i18nl("space.directions.particularEntrance")}</p>
                  <div className="buttonGroup">
                    {space.path[0].entrances.map((entrance) => {
                      return (
                        <InternalLink
                          className="button secondary"
                          key={entrance._id}
                          to={`/directions?from=${entrance._id}&to=${space._id}`}
                        >
                          {i18n(entrance, "displayName")}
                        </InternalLink>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            {space.path.map((step, index) => {
              return (
                <div className="step main" key={step._id}>
                  <span>{i18n(step, "displayName")}</span>
                  {
                    index < space.path.length - 1 ? (
                      <InternalLink
                        className="button secondary"
                        key={step._id}
                        to={`/directions?from=${step._id}&to=${space._id}`}
                      >
                        {i18nl("space.directions.fromHere")}
                      </InternalLink>
                    ) : null

                    /* null */
                  }
                </div>
              );
            })}
          </div>
        </ViewArea>
      </div>
    </Main>
  );
};

export default SpaceView;
