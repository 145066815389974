import { useHistory } from "react-router-dom";
import Main from "../../components/Main";
import goBack from "../../utils/goBack";
import StandardHelmet from "../../components/StandardHelmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { useContext, useRef, useState } from "react";
import { ReactComponent as GoIco } from "./go_white.svg";
import { ReactComponent as ExpandIco } from "./expand.svg";
import { ReactComponent as ReduceIco } from "./reduce.svg";
import { ReactComponent as NoStepsIco } from "./nosteps.svg";
import parse from "html-react-parser";
import { API_URL } from "../../config/api";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import LanguageContext from "../../contexts/LanguageContext";

const DirectionsView = ({ directions }) => {
  const { i18n, i18nl } = useContext(LanguageContext);

  const originName = i18n(directions[0].space, "displayName");
  const destinationName = i18n(
    directions[directions.length - 1].space,
    "displayName"
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const slider = useRef(null);

  const [zoomActive, setZoomActive] = useState(false);
  const [zoomPhotoUrl, setZoomPhotoUrl] = useState(false);

  return (
    <Main className="Directions">
      <StandardHelmet
        title={`${i18nl(
          "directions.header.directionsFor"
        )} ${destinationName} ${i18nl("directions.header.from")} ${originName}`}
        description={`${i18nl(
          "directions.header.readInfo"
        )} ${destinationName} ${i18nl(
          "directions.header.descStartingFrom"
        )} ${originName}`}
      />
      <DirectionsHeader
        slider={slider}
        currentIndex={currentIndex}
        index={currentIndex}
        length={directions.length}
        title={i18n(directions[currentIndex].space, "displayName")}
        subtitle={
          directions[currentIndex]?.entrance?.displayName ? (
            (currentIndex === 0
              ? ""
              : i18nl("directions.header.startingFrom")) +
            " " +
            i18n(directions[currentIndex]?.entrance, "displayName")
          ) : (
            <>&nbsp;</>
          )
        }
      />
      <div className="content">
        <Slider
          ref={slider}
          adaptiveHeight={true}
          className="directionsSlider"
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          infinite={false}
          beforeChange={(oldIndex, newIndex) => {
            setCurrentIndex(newIndex);
          }}
        >
          {directions.map((direction, index) => {
            return (
              <div className="slide" key={index}>
                {direction.steps.length > 0 ? (
                  direction.steps.map((step, index) => {
                    return (
                      <div className="stepWrapper" key={index}>
                        <>
                          {step.type === "EXTERNAL" && (
                            <StepExternal
                              lat={step.lat}
                              long={step.long}
                              addressDisplayName={step.addressDisplayName}
                            />
                          )}
                        </>

                        <>
                          {step.type === "TEXT" && (
                            <StepText text={i18n(step, "text")} />
                          )}
                        </>

                        <>
                          {(step.type === "GRAPHICS" ||
                            step.type === "PHOTO") && (
                            <StepImage
                              photoUrl={i18n(step, "photoUrl")}
                              type={step.type}
                              setZoomActive={setZoomActive}
                              setZoomPhotoUrl={setZoomPhotoUrl}
                            />
                          )}
                        </>
                      </div>
                    );
                  })
                ) : (
                  <div className="noSteps">
                    <NoStepsIco />
                    {i18nl("directions.step.noInfo")} "
                    {direction.space.displayName}"
                  </div>
                )}
              </div>
            );
          })}
        </Slider>
      </div>
      {zoomActive ? (
        <NavigableImage photoUrl={zoomPhotoUrl} setZoomActive={setZoomActive} />
      ) : null}
    </Main>
  );
};

const DirectionsHeader = ({
  index,
  length,
  title,
  subtitle,
  slider,
  currentIndex,
}) => {
  const history = useHistory();
  const { i18nl } = useContext(LanguageContext);

  return (
    <header>
      <div className="closeButtonWrapper">
        <button
          title="Torna indietro"
          onClick={() => {
            goBack(history, "/");
          }}
        ></button>
      </div>
      <div className="text">
        <div className="counter">
          <b>
            {i18nl("directions.header.step")} {index + 1}/{length}
          </b>{" "}
          - {i18nl("directions.header.getTo")}
        </div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      <div className="controlsWrapper">
        <button
          className="prev"
          disabled={index === 0}
          title="Passo precedente"
          onClick={() => {
            slider.current.slickGoTo(currentIndex - 1);
          }}
        ></button>
        <button
          className="next"
          disabled={index === length - 1}
          title="Passo successivo"
          onClick={() => {
            slider.current.slickGoTo(currentIndex + 1);
          }}
        ></button>
      </div>
    </header>
  );
};

const StepExternal = ({ lat, long, addressDisplayName }) => {
  const { i18nl } = useContext(LanguageContext);
  return (
    <div className="step external">
      <div className="heading">
        <b>{i18nl("directions.step.external")}</b>
        <span>{addressDisplayName}</span>
      </div>
      <div className="inner squared">
        <img
          alt={addressDisplayName}
          src={`https://maps.geoapify.com/v1/staticmap?style=osm-carto&width=600&height=600&center=lonlat:${long},${lat}&zoom=18.5&marker=lonlat:${long},${lat};type:awesome;color:%2319b8fc;size:large&apiKey=ec3af81eb0984633b1103a36280cb088`}
        />
        <div className="buttons">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`}
          >
            {i18nl("directions.step.open")} Google Maps <GoIco />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://citymapper.com/directions?endcoord=${lat},${long}&endaddress=${addressDisplayName}`}
          >
            {i18nl("directions.step.open")} Citymapper <GoIco />
          </a>
        </div>
      </div>
    </div>
  );
};

const StepText = ({ text }) => {
  return (
    <div className="step text">
      <div className="inner">{parse(text)}</div>
    </div>
  );
};

const StepImage = ({ photoUrl, type, setZoomActive, setZoomPhotoUrl }) => {
  const { i18nl } = useContext(LanguageContext);

  const clickHandler = (event) => {
    event.stopPropagation();
    setZoomActive(true);
    setZoomPhotoUrl(photoUrl);
  };

  return (
    <div className="step image">
      <div className="heading">
        <b>
          {type === "PHOTO"
            ? i18nl("directions.step.photo")
            : i18nl("directions.step.diagram")}
        </b>
      </div>
      <div className="inner squared">
        <img alt="" src={API_URL + "/mediaElements/" + photoUrl} />
        <div className="buttons">
          <button onClick={clickHandler}>
            {i18nl("directions.step.zoom")} <ExpandIco />
          </button>
        </div>
      </div>
    </div>
  );
};

const NavigableImage = ({ photoUrl, setZoomActive }) => {
  const { i18nl } = useContext(LanguageContext);

  return (
    <div className={`NavigableImage`}>
      <PinchZoomPan position="center" maxScale={3}>
        <img alt="" src={API_URL + "/mediaElements/" + photoUrl} />
      </PinchZoomPan>
      <div className="buttons">
        <button
          onClick={() => {
            setZoomActive(false);
          }}
        >
          {i18nl("directions.step.reduce")} <ReduceIco />
        </button>
      </div>
    </div>
  );
};

export default DirectionsView;
