import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import Main from "../../components/Main";
import goBack from "../../utils/goBack";
import StandardHelmet from "../../components/StandardHelmet";
import ItemList from "../../components/ItemList";
import Item from "../../components/Item";
import spaceSort from "../../utils/spaceSort";
import { useContext } from "react";
import LanguageContext from "../../contexts/LanguageContext";

const ExploreView = ({ mainChildHierarchyItem, mainSpace, childSpaces }) => {
  const history = useHistory();

  let mainChildren;
  let otherChildren;

  if (mainChildHierarchyItem) {
    mainChildren = childSpaces
      .filter((space) => space.hierarchyItem._id === mainChildHierarchyItem._id)
      .sort(spaceSort);
    otherChildren = childSpaces
      .filter((space) => space.hierarchyItem._id !== mainChildHierarchyItem._id)
      .sort(spaceSort);
  }

  const { i18n, i18nl } = useContext(LanguageContext);

  return (
    <Main className="Explore">
      <StandardHelmet title={`Esplora - ${i18n(mainSpace, "displayName")}`} />

      <Header
        title={i18n(mainSpace, "displayName")}
        backAction={() => {
          let fallback;
          if (mainSpace?.parent?._id) {
            fallback = `/explore/${mainSpace.parent._id}`;
          } else {
            fallback = "/";
          }
          goBack(history, fallback);
        }}
        rightLink={
          mainSpace.hierarchyItem.isHidden
            ? undefined
            : `/spaces/${mainSpace._id}`
        }
      />

      <div className="content">
        {mainChildren && mainChildren.length ? (
          <>
            <h2 className="dashed">
              {i18n(mainChildHierarchyItem, "pluralDisplayName")}
            </h2>
            <ItemList>
              {mainChildren.map((space) => {
                return (
                  <Item
                    color="gray"
                    key={space._id}
                    title={i18n(space, "displayName")}
                    linksTo={`/explore/${space._id}`}
                  />
                );
              })}
            </ItemList>
          </>
        ) : null}
        {otherChildren && otherChildren.length ? (
          <>
            <h2 className="dashed">
              {mainChildren && mainChildren.length ? (
                <>
                  {i18nl("explore.body.otherServices")}
                  <br />
                  <span className="subtitle">
                    {i18nl("explore.body.inside")} "
                    {i18n(mainSpace, "displayName")}"
                  </span>
                </>
              ) : (
                "Qui trovi:"
              )}
            </h2>
            <ItemList>
              {otherChildren.map((space) => {
                const labelString = space?.labels?.length
                  ? space.labels.reduce((prev, curr, index) => {
                      if (index === space.labels.length - 1) {
                        return prev + i18n(curr, "singularDisplayName");
                      } else {
                        return prev + i18n(curr, "singularDisplayName") + ", ";
                      }
                    }, "")
                  : null;
                return (
                  <Item
                    color="gray"
                    key={space._id}
                    title={i18n(space, "displayName")}
                    subtitle={labelString}
                    linksTo={`/explore/${space._id}`}
                  />
                );
              })}
            </ItemList>
          </>
        ) : null}
      </div>
    </Main>
  );
};

export default ExploreView;
