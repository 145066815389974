import apiClient from "../api";

let labelList = [];

export const getLabels = async () => {
  if (!labelList.length) {
    try {
      labelList = await apiClient["/labels"].get();
    } catch (error) {
      console.error(error.details);
    }
  }

  return labelList;
};
