const goBack = (history, fallback) => {
  const isInternal = history?.location?.state?.internal ? true : false;

  if (isInternal) {
    history.goBack();
  } else {
    history.push(fallback);
  }
};

export default goBack;
