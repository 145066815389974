const strings = {
  "homepage.intro.title": {
    it: "Dove vuoi andare?",
    en: "Where do you want to go?",
  },
  "homepage.intro.searchExample": {
    it: 'ad esempio: "Aula Platone", "Area Studio", "Mensa"',
    en: 'for example: "Aula Platone", "Study Area", "Canteen"',
  },
  "homepage.intro.placeholder": {
    it: "Cerca un luogo, un servizio...",
    en: "Search for places, services...",
  },
  "homepage.intro.bookmarks": {
    it: "I tuoi preferiti",
    en: "Your bookmarks",
  },
  "homepage.intro.emptyBookmarks": {
    it: "Se aggiungerai degli spazi ai preferiti, i tuoi preferiti compariranno qui.",
    en: "If you bookmark spaces, you will find them here.",
  },
  "homepage.intro.announcements": {
    it: "Annunci",
    en: "Announcements",
  },
  "homepage.explore.title": {
    it: "Esplora",
    en: "Explore",
  },
  "homepage.info.title": {
    it: "Altre informazioni",
    en: "Further information",
  },
  "homepage.info.faq": {
    it: "Domande Frequenti",
    en: "Frequently Asked Questions",
  },
  "homepage.info.showTutorial": {
    it: "Visualizza il tutorial",
    en: "Show me the tutorial",
  },
  "homepage.search.recentSpaces": {
    it: "Luoghi recenti",
    en: "Recent spaces",
  },
  "homepage.search.showByLabel": {
    it: "Visualizza per tipo",
    en: "Show by type",
  },
  "homepage.search.resultsFor": {
    it: "Risultati per",
    en: "Results for",
  },
  "find.filter.filterByCampus": {
    it: "Filtra per campus",
    en: "Filter by campus",
  },
  "find.filter.filterByBuilding": {
    it: "Filtra per edificio",
    en: "Filter by building",
  },
  "find.filter.geolocation": {
    it: "Geolocalizzazione",
    en: "Geolocation",
  },
  "find.filter.looking": {
    it: "Sto cercando la tua posizione...",
    en: "Looking for your position...",
  },
  "find.filter.nearestFirst": {
    it: "Mostra i risultati più vicini per primi",
    en: "Show nearest results first",
  },
  "find.filter.geolocationFail": {
    it: "Il tuo dispositivo non supporta la geolocalizzazione",
    en: "Your device doesn't support geolocation",
  },
  "faq.header.title": {
    it: "Domande frequenti",
    en: "Frequently asked questions",
  },
  "faq.search.placeholder": {
    it: "Cerca fra le domande...",
    en: "Search through the questions...",
  },
  "onboarding.button.start": {
    it: "Iniziamo!",
    en: "Let's start!",
  },
  "onboarding.button.skip": {
    it: "Salta",
    en: "Skip",
  },
  "space.head.title.1": {
    it: "Scopri i servizi di",
    en: "Discover what is offered at",
  },
  "space.head.title.2": {
    it: "e come raggiungere",
    en: "and how to get to",
  },
  "space.head.title.3": {
    it: "presso l'Università Vita - Salute San Raffaele",
    en: "of Università Vita - Salute San Raffaele",
  },
  "space.directions.title": {
    it: "Come arrivare",
    en: "How to get here",
  },
  "space.directions.description.1": {
    it: "Consulta le istruzioni per raggiungere",
    en: "Read the instructions for reaching",
  },
  "space.directions.description.2": {
    it: "visualizza il percorso completo, oppure inizia la navigazione da un punto a tua scelta",
    en: "have a look at the complete path, or start your navigation from a starting point of your choice",
  },
  "space.directions.fromOutside": {
    it: "Raggiungi dall'esterno",
    en: "Get there from outside",
  },
  "space.directions.fromHere": {
    it: "Da qui",
    en: "From here",
  },
  "space.directions.particularEntrance": {
    it: "Vuoi passare da una specifica entrata?",
    en: "Do you want to specify a particular entrance?",
  },
  "directions.header.step": {
    it: "Passo",
    en: "Step",
  },
  "directions.header.getTo": {
    it: "Raggiungi",
    en: "Get to",
  },
  "directions.header.readInfo": {
    it: "Consulta le informazioni per raggiungere",
    en: "Discover how to get to",
  },
  "directions.header.startingFrom": {
    it: "Partendo da",
    en: "Starting from",
  },
  "directions.header.descStartingFrom": {
    it: "partendo da",
    en: "starting from",
  },
  "directions.header.directionsFor": {
    it: "Direzioni per",
    en: "Directions to",
  },
  "directions.header.from": {
    it: "da",
    en: "from",
  },
  "directions.step.external": {
    it: "Indirizzo",
    en: "Address",
  },
  "directions.step.open": {
    it: "Apri",
    en: "Open",
  },
  "directions.step.photo": {
    it: "Fotografia",
    en: "Photo",
  },
  "directions.step.diagram": {
    it: "Schema",
    en: "Diagram",
  },
  "directions.step.zoom": {
    it: "Ingrandisci",
    en: "Zoom",
  },
  "directions.step.reduce": {
    it: "Riduci",
    en: "Go back",
  },
  "directions.step.noInfo": {
    it: "Purtroppo non sono ancora presenti istruzioni per raggiungere",
    en: "Sorry, but at the moment there is no information on how to get to",
  },
  "explore.body.otherServices": {
    it: "Altri servizi",
    en: "Other services",
  },
  "explore.body.inside": {
    it: "in",
    en: "inside",
  },
  "header.left.info": {
    it: "Info",
    en: "Know more",
  },
  "footer.language": {
    it: "Cambia lingua",
    en: "Change language",
  },
};

export default strings;
