import "./index.scss";
import { ReactComponent as CaretLeft } from "./assets/caretLeft.svg";
import { ReactComponent as CaretRight } from "./assets/caretRight.svg";
import { ReactComponent as HeartFull } from "./assets/heartFull.svg";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as Share } from "./assets/share.svg";
import { ReactComponent as HeartEmpty } from "./assets/heartEmpty.svg";

const Icons = { CaretLeft, CaretRight, HeartFull, Close, Share, HeartEmpty };

const Icon = ({ symbol }) => {
  const Icon = Icons[symbol];

  return (
    <span className="Icon">
      <Icon />
    </span>
  );
};

export default Icon;
