import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLabels } from "../../stores/labels";
import { getSpaces } from "../../stores/spaces";
import "./index.scss";
import FindView from "./view";

const Find = () => {
  const { labelId } = useParams();
  const [label, setLabel] = useState();
  const [spaces, setSpaces] = useState();

  useEffect(() => {
    (async () => {
      const spaces = await getSpaces();
      const labels = await getLabels();
      const label = labels.find((label) => label._id === labelId);
      setLabel(label);
      setSpaces(spaces);
    })();
  }, [labelId]);

  return label && spaces ? <FindView label={label} spaces={spaces} /> : null;
};

export default Find;
