import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSpace, getSpaces } from "../../stores/spaces";
import { getHierarchyItems } from "../../stores/hierarchyItems";
import "./index.scss";
import ExploreView from "./view";
import { Redirect } from "react-router-dom";

const Explore = () => {
  const [childSpaces, setChildSpaces] = useState();
  const [mainSpace, setMainSpace] = useState();
  const [mainChildHierarchyItem, setMainChildHierarchyItem] = useState();
  const [redirect, setRedirect] = useState();
  const { spaceId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const mainSpace = await getSpace(spaceId);
        const childSpaces = await getSpaces({ parent: spaceId });
        const hierarchyItems = await getHierarchyItems({ flat: true });

        const currentHierarchyItemId = mainSpace?.hierarchyItem?._id;
        const mainChildHierarchyItem = hierarchyItems.find(
          (hierarchyItem) =>
            hierarchyItem?.parentId === currentHierarchyItemId &&
            hierarchyItem?.isPrimary === true
        );

        if (!mainChildHierarchyItem) {
          setMainSpace(mainSpace);
          setRedirect(true);
        } else {
          setMainChildHierarchyItem(mainChildHierarchyItem);
          setMainSpace(mainSpace);
          setChildSpaces(childSpaces);
        }
      } catch (error) {
        console.error(error.details);
      }
    })();
  }, [spaceId]);

  return redirect ? (
    <Redirect
      to={{ pathname: `/spaces/${mainSpace._id}`, state: { internal: true } }}
    />
  ) : mainSpace && childSpaces && mainChildHierarchyItem ? (
    <ExploreView
      mainChildHierarchyItem={mainChildHierarchyItem}
      mainSpace={mainSpace}
      childSpaces={childSpaces}
    />
  ) : null;
};

export default Explore;
