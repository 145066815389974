import apiClient from "../api";

let hierarchyItemList = [];
let flatHierarchyItemList = [];

export const getHierarchyItems = async ({ flat }) => {
  if (!hierarchyItemList.length || !flatHierarchyItemList.length) {
    try {
      hierarchyItemList = await apiClient["/hierarchyItems"].get();
      flatHierarchyItemList = await apiClient["/hierarchyItems"].get({
        queryParams: { flat: true },
      });
    } catch (error) {
      console.error(error.details);
    }
  }

  return flat ? flatHierarchyItemList : hierarchyItemList;
};
