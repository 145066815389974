import ViewArea from "../../components/ViewArea";
import Item from "../../components/Item";
import ItemList from "../../components/ItemList";
import InternalLink from "../../components/InternalLink";
import Main from "../../components/Main";
import SearchInput from "../../components/SearchInput";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import Img from "../../components/Img";
import AnnouncementPopup from "../../components/AnnouncementPopup";
import { useContext, useEffect, useState } from "react";
import StandardHelmet from "../../components/StandardHelmet";
import { ReactComponent as Logo } from "../../assets/logo-vertical.svg";
import Onboarding from "../Onboarding";
import LanguageContext from "../../contexts/LanguageContext";

const HomeView = ({
  returningUser,
  closeOnboardingHandler,
  resetOnboardingHandler,
  favorites,
  announcements,
  firstLevelItems,
  legalNotes,
  removeAsFavorite,
  visitedSpaces,
  labels,
  spaces,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupInfo, setPopupInfo] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const [searchMode, setSearchMode] = useState(false);
  const [homeHide, setHomeHide] = useState(false);

  const [filteredLabels, setFilteredLabels] = useState(labels);
  const [filteredSpaces, setFilteredSpaces] = useState([]);

  const { i18n, i18nl } = useContext(LanguageContext);

  useEffect(() => {
    const filteredLabels =
      searchValue && searchValue !== ""
        ? labels.filter((label) => {
            const singularMatch = (i18n(label, "singularDisplayName") || "")
              .toLowerCase()
              .includes(searchValue.toLowerCase());
            const pluralMatch = (i18n(label, "pluralDisplayName") || "")
              .toLowerCase()
              .includes(searchValue.toLowerCase());

            const alternativesMatch = label.alternativeNames.some(
              (alternativeName) => {
                return (
                  (alternativeName.singularAlternativeName || "")
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  (alternativeName.pluralAlternativeName || "")
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                );
              }
            );

            return singularMatch || pluralMatch || alternativesMatch;
          })
        : labels;

    const filteredSpaces =
      searchValue && searchValue !== ""
        ? spaces.filter((space) => {
            return (
              (i18n(space, "displayName") || "")
                .toLowerCase()
                .includes(searchValue) ||
              space.alternativeNames.some((alternativeName) =>
                alternativeName.toLowerCase().includes(searchValue)
              )
            );
          })
        : [];

    setFilteredLabels(filteredLabels);
    setFilteredSpaces(filteredSpaces);

    searchMode ? setHomeHide(true) : setHomeHide(false);
  }, [searchMode, searchValue, labels, spaces, i18n]);

  return (
    <>
      {!returningUser ? (
        <Onboarding closeOnboardingHandler={closeOnboardingHandler} />
      ) : null}
      <Main className={`Home ${searchMode ? "searching" : ""}`}>
        <StandardHelmet title="Esplora gli spazi dell'Università Vita - Salute San Raffaele" />
        <div className="intro">
          <div className="logo">
            <Logo alt="UniSR Maps" />
          </div>
          {favorites && announcements ? (
            <h1>{i18nl("homepage.intro.title")}</h1>
          ) : null}
        </div>
        {favorites && announcements ? (
          <>
            <div className="searchInputArea">
              <SearchInput
                name="search"
                label={i18nl("homepage.intro.placeholder")}
                value={searchValue}
                setValue={setSearchValue}
                closeButton={searchMode}
                left={searchMode}
                closeHandler={() => {
                  setSearchValue("");
                  setSearchMode(false);
                }}
                focusHandler={() => {
                  setSearchMode(true);
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 400);
                }}
              />
              <div className="suggestion text-small">
                {i18nl("homepage.intro.searchExample")}
              </div>
            </div>
            {homeHide ? (
              <div className="searchNavigation">
                {!searchValue || searchValue === "" ? (
                  <ViewArea className="visitedSpaces" color="gray" fullwidth>
                    <h2 className="dashed">
                      {i18nl("homepage.search.recentSpaces")}
                    </h2>
                    <VisitedList visitedSpaces={visitedSpaces} />
                  </ViewArea>
                ) : null}
                {filteredLabels && filteredLabels.length > 0 ? (
                  <ViewArea className="labelsView" fullwidth>
                    <h2 className="dashed">
                      {i18nl("homepage.search.showByLabel")}
                    </h2>
                    <LabelList filteredLabels={filteredLabels} />
                  </ViewArea>
                ) : null}
                {searchValue && searchValue !== "" ? (
                  <ViewArea className="resultsView" color="gray" fullwidth>
                    <h2 className="dashed">
                      {i18nl("homepage.search.resultsFor")} “{searchValue}”
                    </h2>
                    <SearchedList filteredSpaces={filteredSpaces} />
                  </ViewArea>
                ) : null}
              </div>
            ) : null}
            {homeHide ? null : (
              <div className="homeNavigation">
                <ViewArea
                  className="favouritesAndAnnouncements"
                  color="gray"
                  fullwidth
                >
                  <div className="favoriteWrapper">
                    <h2 className="dashed">
                      {i18nl("homepage.intro.bookmarks")}
                    </h2>
                    <FavoriteList
                      favorites={favorites}
                      removeAsFavorite={removeAsFavorite}
                    />
                  </div>
                  <div className="announcementWrapper">
                    {announcements && announcements.length ? (
                      <>
                        <h2 className="dashed">
                          {i18nl("homepage.intro.announcements")}
                        </h2>
                        <AnnouncementsSlider
                          announcements={announcements}
                          clickHandler={(announcement) => {
                            setPopupInfo({
                              title: i18n(announcement, "title"),
                              text: i18n(announcement, "text"),
                              photoUrl: announcement.photoUrl,
                            });
                            setIsPopupOpen(true);
                          }}
                        />
                      </>
                    ) : null}
                  </div>
                </ViewArea>
                {firstLevelItems && firstLevelItems.length ? (
                  <ViewArea className="explore" fullwidth>
                    <h1>{i18nl("homepage.explore.title")}</h1>
                    <ExploreList firstLevelItems={firstLevelItems} />
                  </ViewArea>
                ) : null}
                <ViewArea className="otherInfo" fullwidth>
                  <h1>{i18nl("homepage.info.title")}</h1>
                  <LegalNotesList
                    legalNotes={legalNotes}
                    resetOnboardingHandler={resetOnboardingHandler}
                  />
                </ViewArea>
              </div>
            )}
          </>
        ) : null}
      </Main>
      <AnnouncementPopup
        open={isPopupOpen}
        closeHandler={() => {
          setIsPopupOpen(false);
        }}
        title={popupInfo.title}
        text={popupInfo.text}
        photoUrl={popupInfo.photoUrl}
      />
    </>
  );
};

const FavoriteList = ({ favorites, removeAsFavorite }) => {
  const { i18n, i18nl } = useContext(LanguageContext);

  return (
    <ItemList className="FavoriteList">
      {favorites && favorites.length ? (
        favorites.map((favorite) => {
          return favorite ? (
            <Item
              key={favorite._id}
              title={i18n(favorite, "displayName")}
              linksTo={`/spaces/${favorite._id}`}
              actions={[
                {
                  callback: () => {
                    removeAsFavorite(favorite._id);
                  },
                  symbol: "HeartFull",
                },
              ]}
            />
          ) : null;
        })
      ) : (
        <p>
          {i18nl("homepage.intro.emptyBookmarks")}
        </p>
      )}
    </ItemList>
  );
};

const VisitedList = ({ visitedSpaces }) => {
  const { i18n, i18nl } = useContext(LanguageContext);

  return (
    <ItemList className="VisitedList">
      {visitedSpaces && visitedSpaces.length ? (
        visitedSpaces.map((visitedSpace) => {
          return visitedSpace ? (
            <Item
              key={visitedSpace._id}
              title={i18n(visitedSpace, "displayName")}
              linksTo={`/spaces/${visitedSpace._id}`}
              subtitle={i18n(visitedSpace, "textPath")}
            />
          ) : null;
        })
      ) : (
        <p>
          {i18nl("homepage.intro.emptyBookmarks")}
        </p>
      )}
    </ItemList>
  );
};

const SearchedList = ({ filteredSpaces }) => {
  const { i18n } = useContext(LanguageContext);

  return (
    <ItemList className="SearchedList">
      {filteredSpaces && filteredSpaces.length
        ? filteredSpaces.map((filteredSpace) => {
            return (
              <Item
                key={filteredSpace._id}
                title={i18n(filteredSpace, "displayName")}
                linksTo={`/spaces/${filteredSpace._id}`}
                subtitle={i18n(filteredSpace, "textPath")}
              />
            );
          })
        : null}
    </ItemList>
  );
};

const AnnouncementsSlider = ({ announcements, clickHandler }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const widthSetter = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", widthSetter);

    return function cleanup() {
      window.removeEventListener("resize", widthSetter);
    };
  }, []);

  const { i18n } = useContext(LanguageContext);

  return windowWidth < 960 ? (
    <Slider
      className="AnnouncementsSlider"
      autoplay
      autoplaySpeed={5000}
      infinite
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
    >
      {announcements.map((announcement) => {
        return (
          <div
            className="slide"
            key={announcement._id}
            onClick={() => {
              clickHandler(announcement);
            }}
          >
            <b>{i18n(announcement, "title")}</b>
            <br />
            <p>
              {(i18n(announcement, "plainText") || "").substring(0, 90)}
              [&hellip;]
            </p>
          </div>
        );
      })}
    </Slider>
  ) : (
    <div className="announcementList">
      {announcements.map((announcement) => {
        return (
          <div
            className="announcement"
            key={announcement._id}
            onClick={() => {
              clickHandler(announcement);
            }}
          >
            <b>{i18n(announcement, "title")}</b>
            <br />
            <p>
              {(i18n(announcement, "plainText") || "").substring(0, 90)}
              [&hellip;]
            </p>
          </div>
        );
      })}
    </div>
  );
};

const ExploreList = ({ firstLevelItems }) => {
  const { i18n } = useContext(LanguageContext);

  return (
    <div className="ExploreList">
      {firstLevelItems.map((item) => {
        return (
          <div className="item" key={item._id}>
            <InternalLink to={`/explore/${item._id}`}>
              <Img src={item.photoUrl} alt="" width={1920} height={1080} />
            </InternalLink>
            <NavLink to={`/explore/${item._id}`}>
              <span>{i18n(item, "displayName")}</span>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

const LegalNotesList = ({ legalNotes, resetOnboardingHandler }) => {
  const { i18n, i18nl } = useContext(LanguageContext);

  return (
    <ItemList className="LegalNotesList">
      <Item title={i18nl("homepage.info.faq")} linksTo={`/faq`} color="gray" />
      {legalNotes && legalNotes.length
        ? legalNotes.map((legalNote) => {
            return (
              <Item
                key={legalNote._id}
                title={i18n(legalNote, "title")}
                linksTo={`/legalNotes/${legalNote._id}`}
                color="gray"
              />
            );
          })
        : null}
      <Item
        title={i18nl("homepage.info.showTutorial")}
        mainAction={resetOnboardingHandler}
        color="gray"
      />
    </ItemList>
  );
};

const LabelList = ({ filteredLabels }) => {
  const { i18n } = useContext(LanguageContext);

  return (
    <div className="LabelList">
      {filteredLabels && filteredLabels.length > 0
        ? filteredLabels.map((label) => {
            return (
              <NavLink
                key={label._id}
                to={`/find/${label._id}`}
                className="label"
              >
                {i18n(label, "singularDisplayName")}
              </NavLink>
            );
          })
        : null}
    </div>
  );
};

export default HomeView;
