import { useContext } from "react";
import languages from "../../config/languages";
import LanguageContext from "../../contexts/LanguageContext";
import "./index.scss";

const LanguageSwitcher = () => {
  const { switchLanguage, currentLanguage, i18nl } =
    useContext(LanguageContext);

  return (
    <div className="LanguageSwitcher">
      <span>{i18nl("footer.language")}: </span>
      {languages.map((language) => (
        <button
          key={language}
          onClick={() => {
            switchLanguage(language);
          }}
          className={`${currentLanguage === language ? "active" : ""}`}
        >
          {language}
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
