const Favorites = {
  list: () => {
    const list = JSON.parse(window.localStorage.getItem("favorites")) || [];

    return list;
  },
  add: (id) => {
    const currentList =
      JSON.parse(window.localStorage.getItem("favorites")) || [];
    currentList.push(id);

    window.localStorage.setItem("favorites", JSON.stringify(currentList));
  },
  remove: (id) => {
    const currentList =
      JSON.parse(window.localStorage.getItem("favorites")) || [];
    const newList = currentList.filter((item) => item !== id);

    window.localStorage.setItem("favorites", JSON.stringify(newList));
  },
};

export default Favorites;
