import "./index.scss";
import parse from "html-react-parser";
import { useState } from "react";

const Collapsible = ({ title, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`Collapsible ${open ? "open" : ""}`}>
      <h2>
        <button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {title}
        </button>
      </h2>
      <div className="textWrap">
        <div className="text">{parse(text)}</div>
      </div>
    </div>
  );
};

export default Collapsible;
