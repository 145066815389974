import { createClient } from "oa-client";
import { API_URL } from "../config/api";
import specs from "./openapi.json";

const callers = {
  "default-get": async (url) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    url.searchParams.append("frontend", true);

    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      const error = new Error();
      error.details = (await response.json()).error;
      throw error;
    }

    try {
      let json = await response.json();

      if (Array.isArray(json)) {
        json = json.map((item) => {
          Object.keys(item).forEach((key) => {
            if (key.includes("Url") && item[key]) {
              item[key] = API_URL + "/mediaElements/" + item[key];
            }
          });

          return item;
        });
      } else {
        Object.keys(json).forEach((key) => {
          if (key.includes("Url") && json[key]) {
            json[key] = API_URL + "/mediaElements/" + json[key];
          }
        });
      }
      return json;
    } catch (error) {
      return null;
    }
  },
};

const apiClient = createClient(specs, callers, {
  origin: API_URL,
  validationLevel: "off",
});

export default apiClient;
