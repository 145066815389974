import React, { useEffect, useState } from "react";
import languages from "../config/languages";
import strings from "../config/strings";

const LanguageContext = React.createContext();
const LanguageProvider = LanguageContext.Provider;

//Build an ordered list of preferred and supported languages
let browserLocales = (navigator.languages || [navigator.language] || []).map(
  (locale) => locale.substring(0, 2)
);
browserLocales = browserLocales
  .filter((locale, index) => browserLocales.indexOf(locale) === index)
  .filter((locale) => languages.includes(locale));

//Check if a language is being forced
const params = Object.fromEntries(
  new URLSearchParams(window.location.search).entries()
);
const forcedLanguage = params.lang;

const cleanParams = { ...params };
delete cleanParams.lang;
const newQueryString = Object.keys(cleanParams)
  .map((key) => {
    return cleanParams[key]
      ? encodeURIComponent(key) + "=" + encodeURIComponent(cleanParams[key])
      : "";
  })
  .join("&");

window.history.replaceState(
  {},
  document.title,
  document.location.pathname + (newQueryString ? "?" + newQueryString : "")
);

//Set as default language: the first supported language, or a fallback
const defaultLanguage = browserLocales[0] || "en";

const LanguageWrapper = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    forcedLanguage || localStorage.getItem("language") || defaultLanguage
  );

  useEffect(() => {
    const html = document.querySelector("html");
    html.setAttribute("lang", currentLanguage);
    localStorage.setItem("language", currentLanguage);
  }, [currentLanguage]);

  const i18n = (object, baseLabel) => {
    let label;

    if (currentLanguage === languages[0]) {
      label = baseLabel;
    } else {
      label = `${baseLabel}_${currentLanguage}`;
    }

    const value = object ? object[label] : "";

    if (!value || value === "") {
      return object ? object[baseLabel] : "";
    }

    return value;
  };

  const i18nl = (key) => {
    if (strings[key]) {
      if (strings[key][currentLanguage]) {
        return strings[key][currentLanguage];
      } else {
        return strings[key][languages[0]];
      }
    } else {
      return key;
    }
  };

  const switchLanguage = (language) => {
    setCurrentLanguage(language);
  };

  return (
    <LanguageProvider
      value={{
        i18n,
        i18nl,
        currentLanguage,
        setCurrentLanguage,
        switchLanguage,
      }}
    >
      {children}
    </LanguageProvider>
  );
};

export default LanguageContext;
export { LanguageWrapper };
