import "./index.scss";

const Img = ({ className, src, alt, width, height }) => {
  const ratio = height / width;

  if (!src) {
    //src = placeholder;
  } else {
  }

  return (
    <div
      className={`Img ${className} ${src ? "" : "placeholder"}`}
      style={{ paddingTop: ratio * 100 + "%" }}
    >
      {src ? <img src={src} alt={alt} /> : null}
    </div>
  );
};

export default Img;
