import { useEffect, useState } from "react";
import apiClient from "../../api";
import FaqView from "./view";
import "./index.scss";

const Faq = () => {
  const [frequentQuestions, setFrequentQuestions] = useState([]);

  useEffect(() => {
    (async () => {
      const frequentQuestions = (
        await apiClient["/frequentQuestions"].get()
      ).filter((frequentQuestion) => !frequentQuestion.hidden);
      frequentQuestions.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
      setFrequentQuestions(frequentQuestions);
    })();
  }, []);

  return <FaqView frequentQuestions={frequentQuestions} />;
};

export default Faq;
