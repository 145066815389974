import { useEffect, useState } from "react";
import apiClient from "../../api";
import "./index.scss";
import OnboardingView from "./view";

const Onboarding = ({ closeOnboardingHandler }) => {
  const [onboardingSteps, setOnboardingSteps] = useState([]);

  useEffect(() => {
    (async () => {
      const onboardingSteps = await apiClient["/onboardingSteps"].get();
      setOnboardingSteps(onboardingSteps);
    })();
  }, []);

  return (
    <OnboardingView
      onboardingSteps={onboardingSteps}
      closeOnboardingHandler={closeOnboardingHandler}
    />
  );
};

export default Onboarding;
