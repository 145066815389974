import "./index.scss";
import { NavLink, Link } from "react-router-dom";
import Icon from "../Icon";
import InternalLink from "../InternalLink";
import { ReactComponent as Logo } from "../../assets/logo-horizontal-small.svg";
import { useContext } from "react";
import LanguageContext from "../../contexts/LanguageContext";

const Header = ({
  title,
  backLink,
  backAction,
  rightLink,
  notSemantic,
  CustomRight,
}) => {
  const { i18nl } = useContext(LanguageContext);

  return (
    <header className="Header">
      <div className="textWrapper">
        <div className="left">
          {backLink ? (
            <NavLink to={backLink} className="backLink">
              <Icon symbol="CaretLeft" />
            </NavLink>
          ) : (
            <button onClick={backAction} className="backLink">
              <Icon symbol="CaretLeft" />
            </button>
          )}
          {notSemantic ? (
            <span className="title">{title}</span>
          ) : (
            <h1>{title}</h1>
          )}
        </div>
        {rightLink ? (
          <div className="right">
            <InternalLink className="rightLink" to={rightLink}>
              {i18nl("header.left.info")}
            </InternalLink>
          </div>
        ) : CustomRight ? (
          <CustomRight />
        ) : null}
      </div>
      <div className="logoWrapper">
        <Link to="/">
          <Logo />
        </Link>
      </div>
    </header>
  );
};

export default Header;
