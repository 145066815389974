import Slider from "react-slick";
import parse from "html-react-parser";
import { useContext, useEffect, useState } from "react";
import LanguageContext from "../../contexts/LanguageContext";

const OnboardingView = ({ onboardingSteps, closeOnboardingHandler }) => {
  const [isLast, setIsLast] = useState(false);

  const loaded = (image) => {
    const top =
      image.getBoundingClientRect().height / 2 +
      image.getBoundingClientRect().top;

    const buttons = document.querySelectorAll(".Onboarding .slick-arrow");
    buttons.forEach((button) => {
      button.style.top = `${top}px`;
    });
  };

  window.onresize = () => {
    const image = document.querySelector(".image");
    if (image) {
      loaded(image);
    }
  };

  useEffect(() => {
    const image = document.querySelector(".image");
    if (image) {
      if (image.complete) {
        loaded(image);
      } else {
        image.addEventListener("load", () => {
          loaded(image);
        });
      }
    }
  }, [onboardingSteps]);

  const { i18n, i18nl } = useContext(LanguageContext);

  return (
    <div className={`Onboarding ${isLast ? "isLast" : ""}`}>
      <Slider
        className={`OnboardingSlider`}
        autoplay={false}
        infinite={false}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        dots={true}
        beforeChange={(oldIndex, newIndex) => {
          if (newIndex === onboardingSteps.length - 1) {
            setIsLast(true);
          } else {
            setIsLast(false);
          }
        }}
      >
        {onboardingSteps.map((onboardingStep) => {
          return (
            <div className="slide" key={onboardingStep._id}>
              <div className="wrapper">
                <div className="imgWrap">
                  <img
                    className="image"
                    src={onboardingStep.photoUrl}
                    alt={onboardingStep.title}
                  />
                </div>
                <h1>{i18n(onboardingStep, "title")}</h1>
                <div className="text">
                  {parse(i18n(onboardingStep, "text") || "")}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="skipArea">
        <button
          className="skip"
          onClick={(event) => {
            event.preventDefault();
            closeOnboardingHandler();
          }}
        >
          {isLast ? (
            i18nl("onboarding.button.start")
          ) : (
            <>{i18nl("onboarding.button.skip")} &gt;</>
          )}
        </button>
      </div>
    </div>
  );
};

export default OnboardingView;
