const spaceSort = (a, b) => {
  if (a.weight < b.weight) return 1;
  if (a.weight > b.weight) return -1;
  if (typeof a.weight === "undefined" && typeof b.weight === "undefined") {
    return a.displayName.localeCompare
      ? a.displayName.localeCompare(b.displayName, "it", {
          sensitivity: "base",
        })
      : 0;
  } else {
    return 0;
  }
};

export default spaceSort;
