import "./index.scss";
import DirectionsView from "./view";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import apiClient from "../../api";

const Directions = () => {
  const history = useHistory();
  const [directions, setDirections] = useState();

  useEffect(() => {
    (async () => {
      const searchString = history.location.search;
      const { from, to } = queryString.parse(searchString);

      const directions = await apiClient["/directions"].get({
        queryParams: { from, to },
      });
      setDirections(directions);
    })();
  }, [history.location.search]);

  return directions ? <DirectionsView directions={directions} /> : null;
};

export default Directions;
