import { useEffect, useState } from "react";
import HomeView from "./view";
import "./index.scss";
import apiClient from "../../api";
import Favorites from "../../utils/Favorites";
import { getSpaces } from "../../stores/spaces";
import spaceSort from "../../utils/spaceSort";
import Visited from "../../utils/Visited";
import { getLabels } from "../../stores/labels";

const Home = () => {
  const [favorites, setFavorites] = useState();
  const [announcements, setAnnouncements] = useState();
  const [firstLevelItems, setFirstLevelItems] = useState();
  const [legalNotes, setLegalNotes] = useState();
  const [spaces, setSpaces] = useState();
  const [labels, setLabels] = useState();
  const [visitedSpaces, setVisitedSpaces] = useState();

  const [returningUser, setReturningUser] = useState(
    localStorage.getItem("returningUser")
  );

  const closeOnboardingHandler = () => {
    localStorage.setItem("returningUser", true);
    setReturningUser(true);
  };

  const resetOnboardingHandler = () => {
    setReturningUser(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const [spaces, announcements, legalNotes, labels] = await Promise.all([
          getSpaces(),
          apiClient["/announcements"].get(),
          apiClient["/legalNotes"].get(),
          getLabels(),
        ]);
        const firstLevelItems = spaces
          .filter((space) => !space.hierarchyItem.parentId)
          .sort(spaceSort);

        const favoriteIds = Favorites.list();

        const favorites = spaces.filter((space) =>
          favoriteIds.includes(space._id)
        );

        const visitedSpaces = Visited.list().map((visitedSpaceId) =>
          spaces.find((space) => space._id === visitedSpaceId)
        );

        setFavorites(favorites);
        setFirstLevelItems(firstLevelItems);
        setAnnouncements(announcements);
        setLegalNotes(legalNotes);
        setSpaces(spaces);
        setVisitedSpaces(visitedSpaces);
        setLabels(labels);
      } catch (error) {
        console.error(error.details);
      }
    })();
  }, []);

  const removeAsFavorite = (id) => {
    Favorites.remove(id);
    setFavorites(favorites.filter((favorite) => favorite._id !== id));
  };

  return spaces && labels ? (
    <HomeView
      returningUser={returningUser}
      closeOnboardingHandler={closeOnboardingHandler}
      resetOnboardingHandler={resetOnboardingHandler}
      favorites={favorites}
      announcements={announcements}
      firstLevelItems={firstLevelItems}
      legalNotes={legalNotes}
      removeAsFavorite={removeAsFavorite}
      visitedSpaces={visitedSpaces}
      spaces={spaces}
      labels={labels}
    />
  ) : null;
};

export default Home;
