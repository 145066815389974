const showToast = (message) => {
  const toastEl = document.createElement("div");
  toastEl.className = "Toast";
  toastEl.innerText = message;
  document.body.append(toastEl);

  setTimeout(() => {
    toastEl.setAttribute("data-toast-show", "");

    setTimeout(() => {
      toastEl.removeAttribute("data-toast-show");

      setTimeout(() => {
        toastEl.remove();
      }, 1000);
    }, 3000);
  }, 100);
};

export default showToast;
