import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import Item from "../../components/Item";
import ItemList from "../../components/ItemList";
import Main from "../../components/Main";
import StandardHelmet from "../../components/StandardHelmet";
import goBack from "../../utils/goBack";
import spaceSort from "../../utils/spaceSort";
import showToast from "../../utils/showToast";
import { ReactComponent as IcoFilterInactive } from "./filter-inactive.svg";
import { ReactComponent as IcoFilterActive } from "./filter-active.svg";
import queryString from "query-string";
import pointDistance from "point-distance";
import LanguageContext from "../../contexts/LanguageContext";

const FindView = ({ label, spaces }) => {
  const history = useHistory();

  const [filteredSpaces, setFilteredSpaces] = useState();
  const [firstLevelItems, setFirstLevelItems] = useState([]);
  const [secondLevelItems, setSecondLevelItems] = useState([]);
  const [firstLevelFilter, setFirstLevelFilter] = useState();
  const [secondLevelFilter, setSecondLevelFilter] = useState();

  const [geolocation, setGeolocation] = useState(false);
  const [position, setPosition] = useState(false);
  const [loadingGeolocation, setLoadingGeolocation] = useState(false);

  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    const searchString = history.location.search;
    const searchObject = queryString.parse(searchString);
    if (searchObject.filter1) {
      setFirstLevelFilter(searchObject.filter1);
      if (searchObject.filter2) {
        setSecondLevelFilter(searchObject.filter2);
      }
    }
    if (searchObject.geolocation) {
      setGeolocation(true);
    }
    // eslint-disable-next-line
  }, []);

  const { i18n, i18nl } = useContext(LanguageContext);

  useEffect(() => {
    if (geolocation) {
      if (navigator.geolocation) {
        setLoadingGeolocation(true);

        navigator.geolocation.getCurrentPosition(
          (returnedPosition) => {
            setLoadingGeolocation(false);
            setPosition({
              lat: returnedPosition.coords.latitude,
              long: returnedPosition.coords.longitude,
            });

            history.replace(
              history.location.pathname +
                "?" +
                (firstLevelFilter
                  ? "filter1=" +
                    firstLevelFilter +
                    (secondLevelFilter ? "&filter2=" + secondLevelFilter : "")
                  : "") +
                "&geolocation=true"
            );
          },
          (error) => {
            setLoadingGeolocation(false);
            setGeolocation(false);
            showToast(i18nl("find.filter.geolocationFail"));
            console.log(error);
          }
        );
      }
    } else {
      setPosition(undefined);
      history.replace(
        history.location.pathname +
          "?" +
          (firstLevelFilter
            ? "filter1=" +
              firstLevelFilter +
              (secondLevelFilter ? "&filter2=" + secondLevelFilter : "")
            : "")
      );
    }
  }, [history, geolocation, firstLevelFilter, secondLevelFilter, i18nl]);

  useEffect(() => {
    let filteredSpaces = spaces.filter((space) => {
      const hasLabel = space.labels.some(
        (singleLabel) => singleLabel._id === label._id
      );
      const childOfFirstFilter = firstLevelFilter
        ? space.path.some((parent) => parent._id === firstLevelFilter)
        : true;
      const childOfSecondFilter = secondLevelFilter
        ? space.path.some((parent) => parent._id === secondLevelFilter)
        : true;
      return hasLabel && childOfFirstFilter && childOfSecondFilter;
    });

    //geolocation
    if (position) {
      filteredSpaces = filteredSpaces
        .map((space) => {
          return {
            ...space,
            distance: pointDistance(
              [space.position.lat, space.position.long],
              [position.lat, position.long]
            ),
          };
        })
        .sort((a, b) => {
          if (a.distance > b.distance) return 1;
          if (a.distance < b.distance) return -1;
          return 0;
        });
    }
    setFilteredSpaces(filteredSpaces);

    const firstLevelItems = spaces
      .filter((space) => !space.hierarchyItem.parentId)
      .sort(spaceSort);
    setFirstLevelItems(firstLevelItems);

    //second level items are items that are child of the selected first level filter, but also belong to a primary hierarchyItem
    const secondLevelItems = firstLevelFilter
      ? spaces
          .filter(
            (space) =>
              space?.parent?._id === firstLevelFilter &&
              space?.hierarchyItem?.isPrimary
          )
          .sort(spaceSort)
      : undefined;
    setSecondLevelItems(secondLevelItems);
  }, [label, spaces, firstLevelFilter, secondLevelFilter, position]);

  return label && filteredSpaces ? (
    <Main className="Find">
      <StandardHelmet title={i18n(label, "pluralDisplayName")} />
      <Header
        title={i18n(label, "pluralDisplayName")}
        backAction={() => {
          goBack(history, "/");
        }}
        CustomRight={() => (
          <div className="FilterTrigger">
            <button
              className={`${filterOpen ? "open" : "closed"} ${
                firstLevelFilter || position ? "active" : ""
              }`}
              title="Apri i filtri"
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
            >
              {filterOpen ? <IcoFilterActive /> : <IcoFilterInactive />}
            </button>
          </div>
        )}
      />
      <div className="columnWrap">
        <div className={`filters ${filterOpen ? "open" : "closed"}`}>
          <div className="filtersWrapper">
            <div className="filtersBox">
              <div className="section">
                <b>{i18nl("find.filter.filterByCampus")}</b>
                <div className="filterChips">
                  {firstLevelItems.map((item) => {
                    return (
                      <div
                        key={item._id}
                        className={`item ${
                          firstLevelFilter === item._id ? "active" : ""
                        }`}
                        onClick={() => {
                          if (firstLevelFilter === item._id) {
                            setFirstLevelFilter(undefined);
                            setSecondLevelFilter(undefined);
                            history.replace(history.location.pathname);
                          } else {
                            setFirstLevelFilter(item._id);
                            setSecondLevelFilter(undefined);
                            history.replace(
                              history.location.pathname +
                                "?filter1=" +
                                item._id +
                                (geolocation ? "&geolocation=true" : "")
                            );
                          }
                        }}
                      >
                        {i18n(item, "displayName")}
                      </div>
                    );
                  })}
                </div>
              </div>
              {firstLevelFilter &&
              secondLevelItems &&
              secondLevelItems.length > 0 ? (
                <div className="section">
                  <b>{i18nl("find.filter.filterByBuilding")}</b>
                  <div className="filterChips">
                    {secondLevelItems.map((item) => {
                      return (
                        <div
                          key={item._id}
                          className={`item ${
                            secondLevelFilter === item._id ? "active" : ""
                          }`}
                          onClick={() => {
                            if (secondLevelFilter === item._id) {
                              setSecondLevelFilter(undefined);
                              history.replace(
                                history.location.pathname +
                                  "?filter1=" +
                                  firstLevelFilter +
                                  (geolocation ? "&geolocation=true" : "")
                              );
                            } else {
                              setSecondLevelFilter(item._id);
                              history.replace(
                                history.location.pathname +
                                  "?filter1=" +
                                  firstLevelFilter +
                                  "&filter2=" +
                                  item._id +
                                  (geolocation ? "&geolocation=true" : "")
                              );
                            }
                          }}
                        >
                          {i18n(item, "displayName")}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="section">
                <b>{i18nl("find.filter.geolocation")}</b>
                <label>
                  <input
                    type="checkbox"
                    checked={geolocation}
                    onChange={() => {
                      setGeolocation(!geolocation);
                    }}
                    disabled={loadingGeolocation}
                  />
                  <span className="toggle"></span>
                  <span className="text">
                    {loadingGeolocation
                      ? i18nl("find.filter.looking")
                      : i18nl("find.filter.nearestFirst")}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          {loadingGeolocation ? (
            <div className="loadingGeolocation">
              {i18nl("find.filter.looking")}
            </div>
          ) : null}
          <ItemList>
            {filteredSpaces.map((space) => {
              return (
                <Item
                  color="gray"
                  key={space._id}
                  title={i18n(space, "displayName")}
                  subtitle={i18n(space, "textPath")}
                  linksTo={`/spaces/${space._id}`}
                />
              );
            })}
          </ItemList>
        </div>
      </div>
    </Main>
  ) : null;
};

export default FindView;
