import "./index.scss";
import SearchIco from "./search-ico.svg";

const SearchInput = ({
  value,
  setValue,
  name,
  label,
  left,
  closeButton,
  closeHandler,
  focusHandler,
}) => {
  return (
    <div className={`SearchInput ${left ? "left" : ""}`}>
      <input
        spellCheck="false"
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="off"
        name={name}
        value={value}
        className={value ? "filled" : ""}
        onChange={(event) => setValue(event.target.value)}
        onFocus={focusHandler}
      />
      <label htmlFor={name}>
        <img alt="" src={SearchIco} />{" "}
        <span className={value ? "hidden" : ""}>{label}</span>
      </label>
      {closeButton && closeHandler ? (
        <button
          title="Chiudi la modalità di ricerca"
          onClick={closeHandler}
        ></button>
      ) : null}
    </div>
  );
};

export default SearchInput;
