import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Favorites from "../../utils/Favorites";
import { getSpace } from "../../stores/spaces";
import mobileOperatingSystem from "../../utils/mobileOperatingSystem";
import showToast from "../../utils/showToast";
import "./index.scss";
import SpaceView from "./view";
import Visited from "../../utils/Visited";
import LanguageContext from "../../contexts/LanguageContext";

const Space = () => {
  const [space, setSpace] = useState();
  const [isFavorite, setIsFavorite] = useState();
  const { spaceId } = useParams();

  const { i18n } = useContext(LanguageContext);

  const shareHandler = () => {
    const currentUrl = window.location.href;

    if (mobileOperatingSystem() && navigator.share) {
      navigator.share({
        url: currentUrl,
        title: "UniSR Maps: " + i18n(space, "displayName"),
      });
    } else {
      navigator.clipboard.writeText(currentUrl).then(() => {
        showToast("Il link è stato copiato negli appunti!");
      });
    }
  };

  const addAsFavorite = () => {
    Favorites.add(space._id);
    setIsFavorite(true);
  };

  const removeAsFavorite = () => {
    Favorites.remove(space._id);
    setIsFavorite(false);
  };

  useEffect(() => {
    (async () => {
      const space = await getSpace(spaceId);

      setIsFavorite(Favorites.list().includes(space._id));
      setSpace(space);

      Visited.add(space._id);
    })();
  }, [spaceId]);

  return space ? (
    <SpaceView
      space={space}
      shareHandler={shareHandler}
      isFavorite={isFavorite}
      addAsFavorite={addAsFavorite}
      removeAsFavorite={removeAsFavorite}
    />
  ) : null;
};

export default Space;
