import "./assets/normalize.css";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import Home from "./routes/Home";
import Faq from "./routes/Faq";

import { useEffect } from "react";
import LegalNote from "./routes/LegalNote";
import Space from "./routes/Space";

import { HelmetProvider } from "react-helmet-async";
import Explore from "./routes/Explore";
import Find from "./routes/Find";
import Directions from "./routes/Directions";
import { LanguageWrapper } from "./contexts/LanguageContext";
import LanguageSwitcher from "./components/LanguageSwitcher";

var { DateTime } = require("luxon");

const App = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <HelmetProvider>
      <LanguageWrapper>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/faq" exact>
              <Faq />
            </Route>
            <Route path="/legalNotes/:legalNoteId">
              <LegalNote />
            </Route>
            <Route path="/spaces/:spaceId">
              <Space />
            </Route>
            <Route path="/explore/:spaceId">
              <Explore />
            </Route>
            <Route path="/find/:labelId">
              <Find />
            </Route>
            <Route path="/directions">
              <Directions />
            </Route>
          </Switch>
        </Router>
        <footer>
          © {DateTime.now().toFormat("yyyy")} UNIVERSITÀ{" "}
          <span className="nowrap">VITA - SALUTE</span>{" "}
          <span className="nowrap">SAN RAFFAELE</span>
          <LanguageSwitcher />
        </footer>
      </LanguageWrapper>
    </HelmetProvider>
  );
};

export default App;
