import { useHistory } from "react-router-dom";

const InternalLink = ({ to, children, className }) => {
  const history = useHistory();

  return (
    <a
      className={className}
      href={to}
      state={{ internal: true }}
      onClick={(event) => {
        event.preventDefault();
        history.push(to, { internal: true });
      }}
    >
      {children}
    </a>
  );
};

export default InternalLink;
