import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../../api";
import "./index.scss";
import LegalNoteView from "./view";

const LegalNote = () => {
  const [legalNote, setLegalNote] = useState({});
  const { legalNoteId } = useParams();

  useEffect(() => {
    (async () => {
      const legalNote = await apiClient["/legalNotes/{legalNoteId}"].get({
        pathParams: { legalNoteId },
      });
      setLegalNote(legalNote);
    })();
  }, [legalNoteId]);

  return <LegalNoteView legalNote={legalNote} />;
};

export default LegalNote;
