import "./index.scss";

const ViewArea = ({ children, color, fullwidth, className }) => {
  return (
    <div
      className={`ViewArea ${color && "color-" + color} ${
        fullwidth && "fullwidth"
      } ${className}`}
    >
      <div className="viewWrapper">{children}</div>
    </div>
  );
};

export default ViewArea;
