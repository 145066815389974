import "./index.scss";

const Main = ({ className, children, style }) => {
  return (
    <main style={style} className={`mainArea ${className}`}>
      {children}
    </main>
  );
};

export default Main;
