import Icon from "../Icon";
import InternalLink from "../InternalLink";
import "./index.scss";

const Item = ({ color, actions, title, subtitle, linksTo, mainAction }) => {
  actions = actions
    ? actions
    : linksTo
    ? [{ linksTo, symbol: "CaretRight" }]
    : mainAction
    ? [{ callback: mainAction, symbol: "CaretRight" }]
    : [];
  const Text = ({ className }) => {
    return (
      <div className={className ? className : ""}>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
    );
  };

  return (
    <li className={`Item ${color ? `color-${color}` : ""}`}>
      {linksTo ? (
        <InternalLink className="text" to={linksTo}>
          <Text />
        </InternalLink>
      ) : mainAction ? (
        <button className="text" onClick={mainAction}>
          <Text />
        </button>
      ) : (
        <Text className="text" />
      )}
      <div className="actions">
        {actions.map((action, index) => {
          if (action.linksTo) {
            return (
              <span key={index} className="action">
                <InternalLink to={action.linksTo}>
                  <Icon symbol={action.symbol} />
                </InternalLink>
              </span>
            );
          } else if (action.callback) {
            return (
              <span key={index} className="action">
                <button onClick={action.callback}>
                  <Icon symbol={action.symbol} />
                </button>
              </span>
            );
          } else {
            return null;
          }
        })}
      </div>
    </li>
  );
};

export default Item;
