import { useContext, useEffect, useState } from "react";
import Collapsible from "../../components/Collapsible";
import Header from "../../components/Header";
import Main from "../../components/Main";
import SearchInput from "../../components/SearchInput";
import StandardHelmet from "../../components/StandardHelmet";
import LanguageContext from "../../contexts/LanguageContext";

const FaqView = ({ frequentQuestions }) => {
  const [searchString, setSearchString] = useState("");

  const [filteredFrequentQuestions, setFilteredFrequentQuestions] =
    useState(frequentQuestions);

  const { i18n, i18nl } = useContext(LanguageContext);

  useEffect(() => {
    if (
      searchString === "" ||
      searchString === null ||
      searchString === undefined
    ) {
      setFilteredFrequentQuestions(frequentQuestions);
    } else {
      setFilteredFrequentQuestions(
        frequentQuestions.filter((question) => {
          return (
            (i18n(question, "title") || "")
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            (i18n(question, "text") || "")
              .toLowerCase()
              .includes(searchString.toLowerCase())
          );
        })
      );
    }
  }, [frequentQuestions, searchString, i18n]);

  return (
    <Main className="Faq">
      <StandardHelmet title={i18nl("faq.header.title")} />
      <Header title={i18nl("faq.header.title")} backLink="/" />
      <SearchInput
        value={searchString}
        setValue={setSearchString}
        name="search"
        label={i18nl("faq.search.placeholder")}
        left
      />
      <div className="content">
        <ul>
          {filteredFrequentQuestions.map((frequentQuestion) => {
            return (
              <li key={frequentQuestion._id}>
                <Collapsible
                  title={i18n(frequentQuestion, "title")}
                  text={i18n(frequentQuestion, "text")}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </Main>
  );
};

export default FaqView;
