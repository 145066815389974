import { Helmet } from "react-helmet-async";

const StandardHelmet = ({ title, description }) => {
  title = "UniSR Maps - " + title;
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={document.location.href} />

      <meta property="og:title" content={title} />
      <meta property="og:url" content={document.location.href} />

      {description ? (
        <meta property="og:description" content={description} />
      ) : (
        <meta
          name="description"
          content="Con UniSR Maps puoi esplorare gli spazi dell'Università Vita - Salute San Raffaele."
        />
      )}

      {description ? (
        <meta name="description" content={description} />
      ) : (
        <meta
          name="description"
          content="Con UniSR Maps puoi esplorare gli spazi dell'Università Vita - Salute San Raffaele."
        />
      )}
    </Helmet>
  );
};

export default StandardHelmet;
